class HeaderBox extends Component {
	constructor(el) {
		super(el);
		this.opener = this.$find('[data-opener]');
		this.dropdown = this.$find('[data-dropdown]');
		this.addEventListeners();
	}

	toggleDropdown() {
		this.dropdown.classList.toggle('is-opened');
		this.opener.classList.toggle('is-active');
	}

	hideDropdown() {
		this.dropdown.classList.remove('is-opened');
		this.opener.classList.remove('is-active');
	}

	addEventListeners() {
		if (this.dropdown) {
			this.opener.addEventListener('click', () => this.toggleDropdown());
			document.addEventListener('click', e => {
				if (!this.$el.contains(e.target)) {
					this.hideDropdown();
				}
			});
		}
	}
}